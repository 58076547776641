<template>
  <transition name="fade" appear>
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path:'/notice'}">公告</el-breadcrumb-item>
        <el-breadcrumb-item>公告详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div v-html="content" class="ql-editor text-left" style="margin-top:30px;">
      </div>
    </div>

  </transition>
</template>

<script>
export default {
  data () {
    return {
      noticeCode: '',
      content: `

      `,
    }
  },
  created () {
    this.noticeCode = this.$route.query.noticeCode
    this.getContent()
  },

  methods: {

    getContent () {
      this.$api.notice.queryNoticeDetail(
        {
          noticeCode: this.noticeCode
        }
      ).then(res => {
        if (res.data.code === 200) {
          this.content = res.data.data.content
        }
      })
    }

  }
}
</script>

<style lang="less" >
img {
  width: 100%;
}
</style>